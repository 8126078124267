:root {
  --bs-root-font-size: 16px;
}

.evp-profile {
  width: 33rem;
}

.evp-profile__avatar {
  width: 9.375rem;
  height: 11.25rem;
  position: relative;
}

.evp-profile__avatar:before {
  background-size: 10.3838rem;
  width: 9.375rem;
  height: 11.25rem;
  top: -.8rem;
}

.evp-profile__info {
  background-size: 100%;
  width: 11rem;
  height: 2.5rem;
}

.evp-gallery__col_sq {
  height: 200px;
}

.evp-gallery__col_v {
  height: 280px;
}
/*# sourceMappingURL=index.1a47f5f6.css.map */
